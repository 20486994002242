<template>
  <div class="home">
    <Header></Header>
    <div class="banner">
      <img
        class="banner-img"
        src="@/assets/img/pc/home/banner1.jpg"
      >
      <div
        v-if="!topTipsHide"
        class="top-tips min-main"
      >
        <div class="tips-body">
          <div
            @click="
          visibleAnnouncement=true"
            class="content"
          >关于不法分子冒充“易借速贷”工作人员实施诈骗的声明</div>
          <div
            @click="topTipsHide = true"
            class="btn"
          ><img src="@/assets/img/pc/home/home_btn_close.png" /></div>
        </div>
      </div>
    </div>
    <div class="company">
      <div class="company-main">
        <img src="@/assets/img/pc/home/company.png">
        <div class="company-main-details">
          <img src="@/assets/img/pc/home/joinleft.png">
          <p>浙江未讯科技有限公司的前身为霖梓科技，创办于2016年，是领先的金融科技开放平台。未讯科技作为中国互联网金融协会成员之一，致力于以科技推动金融服务业的数字化升级。作为一家金融科技公司，未讯科技将充分发挥自身的技术优势，携手金融机构合作伙伴为广大用户提供普惠、绿色、可持续的金融服务。</p>
          <img
            class="company-main-details-right"
            src="@/assets/img/pc/home/joinright.png"
          >
          <div class="company-main-details-line"></div>
        </div>
      </div>
      <div class="company-bg">
      </div>
    </div>
    <div class="download">
      <div class="download-left">
        <div class="download-left-content">
          <p class="download-left-content-title">{{downloads.title}}</p>
          <p>{{downloads.des1}}</p>
          <p>{{downloads.des2}}</p>
          <div class="download-left-content-code">
            <img
              class="download-left-content-code-img1"
              :src="downloads.imgUrl1"
            >
            <img
              class="download-left-content-code-img2"
              :src="downloads.imgUrl2"
            >
          </div>
        </div>
      </div>
      <img
        class="download-right"
        src="@/assets/img/pc/home/download_right.png"
      >
    </div>
    <div class="product-advantage min-main">
      <div class="product-advantage-main min-content">
        <div class="header-title">
          <img src="@/assets/img/pc/home/header-title-left.png">
          <div class="header-title-content">
            <p>我们的优势</p>
            <p class="header-title-sub">OUR ADVANTAGE</p>
          </div>
          <img src="@/assets/img/pc/home/header-title-right.png">
        </div>
        <ul>
          <li
            v-for="(item, index) in productAdvantage.list"
            :key="index"
          >
            <img :src="item.imgUrl">
            <div class="content">
              <h2>{{item.title1}}</h2>
              <div class="advantage-line"></div>
              <p>{{item.title2}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="fraud min-mian">
      <div class="min-content">
        <div class="header-title">
          <img src="@/assets/img/pc/home/header-title-left.png">
          <div class="header-title-content">
            <p>易借速贷防诈骗指南</p>
            <p class="header-title-sub">ANTI-FRAUD GUIDE</p>
          </div>
          <img src="@/assets/img/pc/home/header-title-right.png">
        </div>
        <div class="fraud-content">
          <p>
            1、请通过手机官方应用市场或官方网站下载“易借速贷”APP，在任何情况下都不要泄露您的个人账号与手机验证码信息。请注意保护资金及个人隐私安全，确保本人操作。
            <br />2、易借速贷的授信额度来自正规金融机构，仅与个人信用资质有关，不受其他第三方因素影响，请勿与任何声称“帮忙借款”的 “内部员工”“第三方代理”等进行联系和交易。
            <br />3、除易借速贷APP上展示的还款计划外，我们不会以任何理由收取其他附加费用，如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各种费用。如遇相关情况，请拨打官方客服电话400-777-8802 (9:00-21:00)进行核实。
            <br /> 4、易借速贷的借还款信息均以官方APP显示为准，切勿相信“代还款”“提前结清贷款”等名义的转账、汇款。
            <br />5、任何冒用及假借我司名义或合作机构进行违法活动的网站、单位或个人，一经发现，我司将依法追究其法律责任。
          </p>
          <img
            class="fraud-content-icon"
            src="@/assets/img/pc/home/fraud-icon.png"
          >
        </div>
      </div>
    </div>
    <Footer></Footer>
    <div class="float-icon" @mouseleave="handlemouseleave('0')">
      <div @mouseenter="handlemouseenter('1')"></div>
      <div @mouseenter="handlemouseenter('2')"></div>
      <div @mouseenter="handlemouseenter('0')" @click="handleGoService"></div>
      <section class="scan-download" v-if="selectindex === '1'">
        <img src="@/assets/img/pc/home/scan-code.png" alt="">
        <span>扫码下载APP</span>
        <span></span>
      </section>
      <section class="customer-service" v-if="selectindex === '2'">
        <span>400-777-8802</span>
        <span>客服热线电话<span> （9:00-21:00）</span></span>
        <span></span>
      </section>
    </div>
    <el-dialog
      center
      :visible.sync="visibleAnnouncement"
    >
      <div
        slot="title"
        class="title-header"
      >公告</div>
      <div class="not-content">
        <div class="not-title">关于不法分子冒充“易借速贷”官方APP实施诈骗的提示</div>
        近日，浙江未讯科技有限公司旗下信贷平台“易借速贷”（以下简称本平台）发现存在不法分子假冒仿冒我司官方易借速贷APP上架至App Store应用商店。请大家注意识别，认准我司官方易借速贷APP，谨防上当受骗。同时下载时仔细核对LOGO等是否为我司易借速贷官方产品，及时通过官网、微信等渠道联系本平台易借速贷官方进行核实。<br />
        同时，在此郑重警告：本平台将维权到底，决不允许任何个人和机构侵犯本平台、用户及合作伙伴的权益。<br />
        特此声明！<br />

        <p style="text-align: right;">浙江未讯科技有限公司 <br />2024年6月6日</p>

      </div>

    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'
export default {
  data () {
    return {
      visibleAnnouncement: false,
      topTipsHide: false,
      selectindex: 0,
      downloads: {
        title: '易借速贷APP',
        des1: '借钱用钱，就找易借速贷',
        des2: '最高可借200000元，以最终审批为准',
        imgUrl1: require('@/assets/img/pc/home/ios-code.png'),
        imgUrl2: require('@/assets/img/pc/home/android-code.png')

      },
      productAdvantage: {
        list: [
          { imgUrl: require('@/assets/img/pc/home/product_bg1.png'), title1: '额度高', title2: '最高可借200000元' },
          { imgUrl: require('@/assets/img/pc/home/product_bg2.png'), title1: '放款快', title2: '自动审批，最快10秒钟' },
          { imgUrl: require('@/assets/img/pc/home/product_bg3.png'), title1: '息费低', title2: '符合国家标准，还款灵活' }
        ]
      },

    }
  },
  methods: {
    //banner点击处理
    handleBannerClick (_, index) {
      console.log("index = " + index);
      if (index == 1) {
        this.$router.push({ name: 'newsinfo', params: { isOpen: true } })
      }
    },
    handlemouseenter (index) {
      this.selectindex = index
    },
    handlemouseleave () {
      this.selectindex = 0
    },
    handleGoService() {
      ysf('open') 
    }
  },
  mounted () {
    this.visibleAnnouncement = true
  },
  components: {
    Header,
    Footer
  }
}
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?1b3e4921d12099c510553b8b4daf3e92";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

  const w = window
  const d = document
  const n = 'ysf'
  var a = function (w, d, n, a, j) {
    w[n] = w[n] || function () {
      (w[n].a = w[n].a || []).push(arguments)
    }
    const script = document.createElement('script')
    script.setAttribute('src', 'https://qiyukf.com/script/340306fe6b76eecbbbfce5dc3fe49f2d.js?hidden=1')
    document.body.appendChild(script)
  }
  a(w, d, n)

</script>

<style lang="less">
.banner {
  min-width: 1300px;
  overflow: hidden;
  position: relative;
  .banner-img {
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .top-tips {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    height: 50px;
    z-index: 22;
    position: absolute;
    top: 88px;
    left: 0px;
    .tips-body {
      width: 1300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px auto;
    }
    .content {
      flex: 1;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
    }
    .btn {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.el-dialog {
  .not-content {
    padding: 0px 30px;
    font-size: 16px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
    text-align: justify;
    color: #1e1f20;
    line-height: 30px;
    overflow: auto;
    overflow-y: scroll;
    height: 400px;
    .not-title {
      font-size: 20px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
      font-weight: 700;
      text-align: center;
      color: #1e1f20;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .not-content::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
  }

  .not-content::-webkit-scrollbar:vertical {
    width: 8px;
  }

  .not-content::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  .not-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.2);
  }
  .el-dialog__header {
    background: #f7f8fc;
    font-size: 24px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
    font-weight: 700;
    text-align: center;
    color: #ee6c28;
    line-height: 31px;
  }
}

.product-advantage {
  margin: 0 auto;
  background-color: rgba(245, 246, 251, 1);
  padding: 87px 0px;
  display: flex;
  justify-content: center;
  ul {
    display: flex;
    width: 1100px;
    margin-top: 36px;
    justify-content: space-between;
    li {
      position: relative;
      width: 350px;
      img {
        margin: 0 auto;
        width: 350px;
        height: 395px;
        float: left;
      }
      .content {
        color: #1e1f20;
        position: absolute;
        top: 0px;
        padding: 66px 43px 0px;
        h2 {
          font-size: 30px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
          font-weight: 700;
          line-height: 40px;
        }
        p {
          font-size: 20px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          line-height: 26px;
        }
      }
    }
  }
  .advantage-line {
    width: 43px;
    height: 4px;
    opacity: 1;
    background: #4f4f4f;
    margin: 30px 0px;
  }
}
.company {
  margin-top: 40px;
  height: 455px;
  min-width: 1300px;
  position: relative;
  &-bg {
    height: 395px;
    width: 100%;
    background-color: rgba(245, 246, 251, 1);
    z-index: -1;
    position: absolute;
    bottom: 0px;
  }
  &-main {
    display: flex;
    width: 1300px;
    margin: 0 auto;
    position: relative;
    img {
      height: 406px;
      width: 312px;
    }
    &-details {
      width: 802px;
      margin-top: 93px;
      margin-left: 62px;
      p {
        padding-left: 47px;
        width: 608px;
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #1e1f20;
        line-height: 30px;
      }
      img {
        width: 18px;
        height: 15px;
      }
      &-right {
        position: relative;
        left: 722px;
        top: -70px;
      }
      &-line {
        margin-left: 47px;
        margin-top: 20px;
        background-color: #bebebe;
        height: 4px;
        width: 80px;
      }
    }
  }
}
.download {
  width: 1300px;
  margin-top: 17px;
  height: 767px;
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  &-right {
    width: 571px;
    height: 791px;
    z-index: 77;
    position: absolute;
    top: 0px;
    right: -40px;
  }
  &-left {
    margin-top: 162px;
    width: 1147px;
    height: 497px;
    background-image: url("~@/assets/img/pc/home/download_left.png");
    background-size: cover;
    &-content {
      font-size: 20px;
      line-height: 26px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      color: #fff;
      padding: 69px 130px;
      font-weight: 400;
      &-title {
        font-size: 30px;
        line-height: 40px;
      }
      p {
        margin-top: 10px;
      }
      p:last-of-type {
        margin-top: 4px;
      }
      &-code {
        display: flex;
        justify-self: start;
        img {
          margin-top: 33px;
          width: 144px;
          height: 172px;
        }
        img:first-of-type {
          margin-right: 28px;
        }
      }
    }
  }
}
.fraud {
  padding: 55px 0px 36px 0px;
  background-color: white;
  display: flex;
  justify-content: center;
  &-content {
    width: 1300px;
    height: 272px;
    position: relative;
    z-index: 3;
    p {
      // max-height: 702px;
      margin-top: 53px;
      font-size: 16px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      font-weight: 400;
      text-align: justify;
      color: #4f4f4f;
      line-height: 30px;
    }
    &-icon {
      position: absolute;
      bottom: 0px;
      right: 71px;
      width: 174px;
      height: 203px;
      z-index: -1;
    }
  }
}
.float-icon{
  position: fixed;
  top: 300px;
  right: 19.5px;
  width: 70px;
  height: 210px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 1px 10px 0px #d3d3d3; 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  >div{
    width: 48px;
    height: 46px;
    cursor: pointer;
  }
  >div:nth-child(1){
    background: url('~@/assets/img/pc/home/1.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  >div:nth-child(2){
    background: url('~@/assets/img/pc/home/2.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  >div:nth-child(3){
    background: url('~@/assets/img/pc/home/3.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  >div:nth-child(1):hover{
    background: url('~@/assets/img/pc/home/hover_1.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  >div:nth-child(2):hover{
    background: url('~@/assets/img/pc/home/hover_2.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  >div:nth-child(3):hover{
    background: url('~@/assets/img/pc/home/hover_3.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .scan-download{
    width: 86px;
    height: 94px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 1px 4px 0px rgba(225,225,225,0.60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 90px;
    top: -20px;
    img{
      width: 64px;
      height: 64px;
      margin-bottom: 2px;
    }
    span:nth-child(2){
      font-size: 10px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
    }
    span:nth-child(3){
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left-color: #ffffff;
      position: absolute;
      right: -20px;
    }
  }
    .customer-service{
      width: 176px;
      height: 59px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      box-shadow: 0px 1px 4px 0px rgba(225,225,225,0.60); 
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 90px;
      span:nth-child(1){
        font-size: 12px;
        font-family: DIN OT, DIN OT-Bold;
        font-weight: 700;
        color: #333333;
        margin-bottom: 5.5px;
      }
      span:nth-child(2){
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Thin;
        font-weight: 200;
        color: #666666;
        span{
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ee5b0d;
        }
      }
      span:nth-child(3){
        display: block;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: #ffffff;
        position: absolute;
        right: -20px;
      }
    }
}
.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  line-height: 40px;
  font-size: 30px;
  text-align: center;
  color: #1e1f20;
  font-weight: 700;
  img {
    width: 129px;
    height: 20px;
  }
  &-content {
    padding: 0px 65px;
  }
  &-sub {
    margin-top: 9px;
    line-height: 26px;
    letter-spacing: 4px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
  }
}
</style>
